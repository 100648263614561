<template>
  <a-modal
    :width="800"
    centered
    :footer="null"
    destroyOnClose
    title="套餐选择"
    :visible="open"
    @ok="onConfirm"
    @cancel="closeModal"
  >
    <a-row :gutter="24">
      <a-col span="12">
        <a-space>
          <a-input v-model="params.search" placeholder="套餐名称" allowClear />
          <a-button type="primary" @click="init()" icon="search">查询</a-button>
        </a-space>
      </a-col>
      <a-col span="12" style="text-align: center">
        <a-button type="primary" @click="onConfirm">确认</a-button>
      </a-col>
    </a-row>
    <a-table
      :columns="comboChooseColumns"
      :dataSource="dataSet"
      :loading="tableLoading"
      :rowKey="record => record.id"
      :pagination="pagination"
      @change="tableChange"
      :row-selection="{
        type: 'radio',
        selectedRowKeys,
        onChange: onSelectChange
      }"
      style="margin-top: 15px;"
    >
    </a-table>
  </a-modal>
</template>

<script>
import { comboChooseColumns } from '@/utils/maps/combo'
import { salePackageList } from '@/api/sale'

export default {
  name: "ComboChoose",
  data() {
    return {
      open: false,
      dataSet: [],
      params: {},
      pagination: {},
      tableLoading: false,
      selectedRowKeys: [],
      comboChooseColumns
    }
  },
  methods: {
    onSelectChange(keys) {
      this.selectedRowKeys = keys
    },
    openModal(record) {
      this.open = true
      if (!!record) { this.selectedRowKeys = [record.id] }
      this.init()
    },
    tableChange(pagination) {
      this.pagination = { ...this.pagination, ...pagination }
      this.init(pagination.current, pagination.pageSize)
    },
    init(page, page_size) {
      this.tableLoading = true;
      this.params = {
        ...this.params,
        page: page || 1,
        page_size: page_size || 10
      }
      if (!this.pagination?.pageSize || !page || !page_size) {
        this.pagination = { current: 1, total: 0, pageSize: 10 }
      }
      this.getList()
    },
    getList() {
      salePackageList(this.params).then(data => {
        const { results = {}, count = 0 } = data
        this.dataSet = results
        this.pagination.total = count
      }).finally(() => {
        this.tableLoading = false
      })
    },
    onConfirm() {
      const combo = this.dataSet.find(item => item.id === this.selectedRowKeys[0])
      this.$emit('ok', combo)
      this.closeModal()
    },
    closeModal() {
      this.pagination = {}
      this.dataSet = []
      this.params = {}
      this.selectedRowKeys = []
      this.open = false
    },
  }
}
</script>

<style scoped>

</style>
